import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { GriddedContentBlock, Caption } from '../../styles/Utility.styles'

export const BasicImageBlock = styled(GriddedContentBlock)`
  /* Naming Convenience */
  margin-top: 0;
`

export const Image = styled.picture`
  grid-column: 2 / 12;
  width: 100%;

  [data-layout='two-column'] & {
    @media ${DEVICE.laptopG} {
      grid-column: span 12;
    }
  }

  img {
    width: 100%;
  }
`

export const ImageCaption = styled(Caption)`
  grid-column: 2 / 12;
  margin-top: 1.4rem;

  [data-layout='two-column'] & {
    @media ${DEVICE.laptopG} {
      grid-column: span 12;
    }
  }
`
