import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { Helmet } from 'react-helmet'

import * as Styled from './styles/EmbeddedForm.styles'
import { Script } from 'gatsby'

const EmbeddedForm = ({ data, bottomPaddingAdjustment }) => {
  if (!data.primary.form_embed_code?.text) {
    return null
  }
  const id = data.id
  const title = data.primary.form_title ? data.primary.form_title : null
  const body = data.primary.form_body?.richText
    ? data.primary.form_body.richText
    : null
  const embedCode = data.primary.form_embed_code.text

  return (
    <>
      <Helmet>
        <script
          src="https://bbox.blackbaudhosting.com/webforms/bbox-min.js"
          async
        />
      </Helmet>
      <Styled.FormContainer bottomPaddingAdjustment={bottomPaddingAdjustment}>
        <div>
          <Styled.Intro>
            {title && <Styled.Title>{title}</Styled.Title>}
            {body && (
              <Styled.Body>
                {body && <PrismicRichText field={body} />}
              </Styled.Body>
            )}
          </Styled.Intro>
          {/* <Script id={id} src={embedCode} /> */}
          <div dangerouslySetInnerHTML={{ __html: embedCode }} />
        </div>
      </Styled.FormContainer>
    </>
  )
}

export default EmbeddedForm

// TODO: This should be restored once the solution is in place for embedded forms
// import React, { useEffect, useState } from 'react'
// import { PrismicRichText } from '@prismicio/react'
// import { Helmet } from 'react-helmet'

// import * as Styled from './styles/EmbeddedForm.styles'
// import { FORM_TYPE } from '../../util/constants'
// import FormAssemblyForm from './FormAssemblyForm'
// import RaisersEdgeForm from './RaisersEdgeForm'
// import RiddleQuizForm from './RiddleQuizForm'
// import CuratorIoForm from './CuratorIoForm'

// const EmbeddedForm = ({ data, formType, bottomPaddingAdjustment }) => {
//   const [title, setTitle] = useState(null)
//   const [body, setBody] = useState(null)
//   const [formData, setFormData] = useState(null)

//   useEffect(() => {
//     if (!data?.primary) return
//     setTitle(data.primary.form_title)
//     setBody(data.primary.form_body?.richText)
//     setFormData(data.primary)
//   }, [data])

//   if (!formData) {
//     return null
//   }
//   return (
//     <Styled.FormContainer bottomPaddingAdjustment={bottomPaddingAdjustment}>
//       <div>
//         <Styled.Intro>
//           {title && <Styled.Title>{title}</Styled.Title>}
//           {body && (
//             <Styled.Body>
//               {body && <PrismicRichText field={body} />}
//             </Styled.Body>
//           )}
//         </Styled.Intro>
//         {formType === FORM_TYPE.FORM_ASSEMBLY && (
//           <FormAssemblyForm formUrl={formData.form_url} />
//         )}
//         {formType === FORM_TYPE.RAISERS_EDGE && (
//           <RaisersEdgeForm formUrl={formData.raisers_edge_form_id} />
//         )}
//         {formType === FORM_TYPE.RIDDLE_QUIZ && (
//           <RiddleQuizForm riddleId={formData.riddle_id} />
//         )}
//         {formType === FORM_TYPE.CURATOR_IO && (
//           <CuratorIoForm data={formData.curator_id} />
//         )}
//         {formType === FORM_TYPE.CODE_EMBED && (
//           <>
//             <div
//               dangerouslySetInnerHTML={{
//                 __html: formData.form_embed_code?.text,
//               }}
//             />
//           </>
//         )}
//       </div>
//     </Styled.FormContainer>
//   )
// }

// export default EmbeddedForm
