import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { object } from 'prop-types'

import * as Styled from './styles/BodyContentBlock.styles'

const BodyContentBlock = ({ data, bottomPaddingAdjustment }) => (
  // <pre>{JSON.stringify(data, null, 2)}</pre>
  <Styled.ContentGrid bottomPaddingAdjustment={bottomPaddingAdjustment}>
    <Styled.BodyContentBlock>
      <Styled.BodyCopy>
        <PrismicRichText field={data.body_copy.richText} />
      </Styled.BodyCopy>
    </Styled.BodyContentBlock>
  </Styled.ContentGrid>
)

BodyContentBlock.propTypes = {
  data: object,
}

export default BodyContentBlock
