import styled from 'styled-components'
import { GriddedContentBlock } from '../../styles/Utility.styles'
import { DEVICE } from '../../../util/constants'

export const ContentGrid = styled(GriddedContentBlock)`
  margin-top: 0;
`

export const BodyContentBlock = styled.article`
  grid-column: 2 / 12;

  [data-layout='two-column'] & {
    @media ${DEVICE.laptopG} {
      grid-column: span 12;
    }
  }
`

export const BodyCopy = styled.div`
  font-size: 1.6rem;
  line-height: 1.6;
  color: var(--content-background-dark-blue);

  h2 {
    font-size: 2rem;
    line-height: 1.33;
    font-weight: 600;
    color: var(--content-background-dark-blue);
    padding-bottom: 8px;

    @media ${DEVICE.tabletL} {
      font-size: 2.4rem;
      padding-bottom: 10px;
    }
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.78;
    color: var(--highlight-blue);
    padding-bottom: 8px;

    @media ${DEVICE.tabletL} {
      font-size: 2rem;
      padding-bottom: 20px;
    }
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.78;
    color: var(--highlight-blue);
    padding-bottom: 8px;

    @media ${DEVICE.tabletL} {
      font-size: 1.8rem;
      padding-bottom: 20px;
    }
  }

  a {
    color: var(--highlight-blue);
    text-decoration-line: none;

    :hover {
      text-decoration-line: underline;
    }
  }

  p {
    margin-bottom: 2rem;
  }

  ul {
    list-style-type: disc;
  }

  ul,
  ol {
    /* list-style-position: inside; */
    margin-left: 2rem;
    padding: 0 31px 1rem;
  }

  li {
    padding-bottom: 1rem;
  }

  blockquote {
    padding: 0 31px 2rem;
    font-family: 'Open Sans', sans-serif;
    color: var(--highlight-blue);
    font-size: 1.8rem;
    line-height: 1.5;
    font-style: italic;
  }

  @media ${DEVICE.tabletL} {
    font-size: 1.8rem;

    ul,
    ol {
      padding: 0 120px 1rem;
    }

    blockquote {
      padding: 0 120px 2rem;
      font-size: 2rem;
      line-height: 1.8;
    }
  }
`
