import React from 'react'

import { DISPLAY_WIDTH } from '../../util/constants'
import * as Styled from './styles/BasicImageBlock.styles'
import LinkWrapper from './LinkWrapper'

const BasicImageBlock = ({ data, bottomPaddingAdjustment }) => {
  return (
    <Styled.BasicImageBlock
      as="figure"
      bottomPaddingAdjustment={bottomPaddingAdjustment}
    >
      <Styled.Image>
        <LinkWrapper link={data.image_link}>
          {data.image?.thumbnails?.mobile?.url && (
            <source
              media={`(max-width: ${DISPLAY_WIDTH.tabletL - 1}px)`}
              srcSet={data.image?.thumbnails?.mobile?.url}
            />
          )}
          <source
            media={`(min-width: ${DISPLAY_WIDTH.tabletL}px)`}
            srcSet={data.image?.url}
          />
          <img src={data.image?.url} alt={data.image?.alt} />
        </LinkWrapper>
      </Styled.Image>
      <Styled.ImageCaption>{data.caption?.text ?? ''}</Styled.ImageCaption>
    </Styled.BasicImageBlock>
  )
}

export default BasicImageBlock
