import styled from 'styled-components'
import { GriddedContentBlock } from '../../styles/Utility.styles'
import { DEVICE } from '../../../util/constants'

export const Body = styled.div`
  font-size: 1.6rem;
  line-height: 1.6;
  color: var(--content-background-dark-blue);

  p {
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 2rem;
    line-height: 1.33;
    font-weight: 600;
    color: var(--content-background-dark-blue);
    padding-bottom: 8px;

    @media ${DEVICE.tabletL} {
      font-size: 2.4rem;
      padding-bottom: 10px;
    }
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.78;
    color: var(--highlight-blue);
    padding-bottom: 8px;

    @media ${DEVICE.tabletL} {
      font-size: 2rem;
      padding-bottom: 20px;
    }
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.78;
    color: var(--highlight-blue);
    padding-bottom: 8px;

    @media ${DEVICE.tabletL} {
      font-size: 1.8rem;
      padding-bottom: 20px;
    }
  }

  a {
    color: var(--highlight-blue);
    text-decoration-line: none;

    :hover {
      text-decoration-line: underline;
    }
  }

  ul {
    list-style-type: disc;
  }

  ul,
  ol {
    margin-left: 2rem;
    padding: 0 31px 1rem;
  }

  li {
    padding-bottom: 1rem;
  }

  blockquote {
    padding: 0 31px 2rem;
    font-family: 'Open Sans', sans-serif;
    color: var(--highlight-blue);
    font-size: 1.8rem;
    line-height: 1.5;
    font-style: italic;
  }

  @media ${DEVICE.tabletL} {
    font-size: 1.8rem;

    ul,
    ol {
      padding: 0 120px 1rem;
    }

    blockquote {
      padding: 0 120px 2rem;
      font-size: 2rem;
      line-height: 1.8;
    }
  }
`

export const FormContainer = styled(GriddedContentBlock)`
  grid-column: 2 / 12;
  margin-top: 0 !important;

  > div {
    grid-column: 2 / 12;
    max-width: 100%;
  }

  iframe {
    width: 100% !important;
  }

  label {
    font-family: 'Open Sans', Tahoma, sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 1.6rem !important;
    line-height: 1.6 !important;
    color: var(--content-background-dark-blue) !important;
  }

  input[type='radio'] {
    margin-right: 8px;
  }

  input.secondaryAction {
    display: none;
  }

  .wFormContainer {
    margin: 0 !important;
  }

  .wFormContainer .oneChoice .label.postField {
    width: unset !important;
  }

  .wFormContainer .wForm {
    margin-top: 0 !important;
    padding: 0 !important;

    .preField {
      width: auto;
    }
  }

  .wFormContainer .wForm .inputWrapper input[type='text'],
  .wFormContainer .wForm .inputWrapper input[type='password'],
  .wFormContainer .wForm .inputWrapper input[type='file'],
  .wFormContainer .wForm .inputWrapper select:not([multiple]) {
    border-width: 1px !important;
    border-style: solid !important;
    border-color: var(--content-background-dark-blue) !important;
    border-radius: 4px !important;
    height: 38px !important;
  }

  .wFormContainer .wForm .inputWrapper textarea.required:focus {
    border-width: 2px !important;
    border-color: var(--content-background-dark-blue) !important;
  }

  .wFormContainer .wForm form {
    padding: 0 !important;
  }

  .wFormContainer .wForm form div:first-child div {
    margin-top: 0 !important;
  }

  .wFormContainer .wForm .oneField {
    padding: 0 !important;
  }

  .wFormContainer .wForm .actions {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .supportInfo {
    display: none !important;
  }

  .wFormHeader {
    display: none !important;
  }

  .wFormFooter {
    display: none !important;
  }

  .BBFormContainer {
    padding: 0 !important;
  }

  .BBFormSection.BBFormButtonRow {
    margin-bottom: 0 !important;
  }

  .BBFormConfirmation {
    color: var(--content-background-dark-blue) !important;
    font-size: 1.6em !important;
    line-height: 1.357 !important;

    > p {
      margin: 0 0 10px;
    }

    strong {
      font-weight: bold;
    }
  }

  .BBFormErrorBlock {
    background-color: #ffb2b2 !important;
    border: 1px solid #ff6347 !important;
    padding: 25px 10px !important;
  }

  .BBFormErrorItem {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6em !important;
    font-weight: bold;
  }

  .BBFormErrorItem:not(.BBFormClientErrorItem) {
    margin-bottom: 10px !important;
  }

  .BBFormClientErrorItem {
    font-size: 1.5em !important;
    font-weight: normal;
  }

  #bboxdonation_BBEmbeddedForm {
    min-width: 260px !important;
  }

  @media screen and (min-width: 375px) {
    #bboxdonation_BBEmbeddedForm {
      min-width: 320px !important;
    }
  }

  @media screen and (min-width: 600px) {
    #bboxdonation_BBEmbeddedForm {
      min-width: 420px !important;
    }
  }

  label.BBFormRadioLabel.BBFormRadioLabelGivingLevel.BBFormRadioLabelGivingLevelSelected,
  .BBFormFieldLabelEdit.BBFormRadioPaymentSelected {
    background-color: #1a5dc8 !important;
    color: #fff !important;
  }

  div#bboxdonation_goal_progressBarRaised {
    background-color: #1a5dc8 !important;
  }

  .BBFormContainer .BBFormFieldContainer,
  .BBFormProgress-Amount {
    color: #101f73 !important;
  }

  .BBFormRadioLabelGivingLevel {
    border: 1px solid #101f73 !important;
  }

  .BBFormTextbox,
  .BBFormSelectList,
  .BBFormTextArea {
    border-radius: 5px !important;
    border-color: #101f73 !important;
    color: #101f73 !important;
  }

  .BBFormFieldLabel {
    font-size: 1.6em;
  }

  .BBFormSubmitbutton {
    background-color: #101f73 !important;
    font-size: 1.6em !important;
    letter-spacing: 0.02em !important;
  }

  @media screen and (max-width: 768px) {
    .BBFormFieldLabel {
      text-align: left !important;
    }

    .BBTwoFields .BBFormTextbox,
    .BBTwoFields .BBFormSelectList {
      width: 100% !important;
      max-width: 350px !important;
      margin-right: 0 !important;
      margin-bottom: 4px !important;
    }

    .BBDFormSectionComments,
    .BBDFormSectionComments * {
      margin-left: 0 !important;
    }

    .BBDFormSectionComments fieldset {
      margin-left: 17px !important;
    }
  }
`

export const Intro = styled.div`
  margin-bottom: 2em;
`

export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.33;
  font-weight: 600;
  color: var(--content-background-dark-blue);
  padding-bottom: 8px;

  @media ${DEVICE.tabletL} {
    font-size: 2.4rem;
    padding-bottom: 10px;
  }
`
